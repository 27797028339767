import Logger from './Logger';

const logger = new Logger('UrlFactory');

export function getSignalingUrl(peerId, roomId, iAccount)
{
	logger.error("generating url for %s", roomId);
	logger.error("i_account is %s", iAccount);
	
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;
	
	var url = "";

	if (iAccount) {
		url = `wss://${window.location.hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&iAccount=${iAccount}`;
	} else {
		url = `wss://${window.location.hostname}:${port}/?peerId=${peerId}&roomId=${roomId}`;
	}

	return url;
}

export function getApiUrl(method) {
	const port = process.env.NODE_ENV !== 'production' ?
		window.config.developmentPort
		:
		window.config.productionPort;

	return `https://${window.location.hostname}:${port}/${method}`;
}

export function getFirstCheckSignalingUrl()
{
	const port =
	process.env.NODE_ENV !== 'production' ?
		window.config.developmentPort
		:
		window.config.productionPort;

		
	return `wss://${window.location.hostname}:${port}/?action=check`;
}

export function getLoginCheckSignallingUrl()
{
	const port =
	process.env.NODE_ENV !== 'production' ?
		window.config.developmentPort
		:
		window.config.productionPort;

		
	return `wss://${window.location.hostname}:${port}/?action=login`;
	
}